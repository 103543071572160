import React from 'react';
import { Disclosure } from '@headlessui/react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Socials from './SocialsIcons';
import PaymentIcons from './PaymentIcons';
import Rewards from './Rewards';
import Link from 'next/link';
import { cn } from '../../../utils';

const navigation = [
  {
    heading: 'GOURMET BASKET',
    items: [
      {
        name: 'About Us',
        url: '/about/',
      },
      {
        name: 'Terms and Conditions',
        url: '/terms-and-conditions/',
      },
      {
        name: 'Privacy and Security',
        url: '/privacy-and-security/',
      },
      {
        name: 'Careers',
        url: '/carrees/',
      },
      {
        name: 'Blog',
        url: 'https://gourmetbasket.com.au/blog/',
      },
    ],
  },
  {
    heading: 'HELP & SUPPORT',
    items: [
      {
        name: 'Delivery Calculator',
        url: '/delivery',
      },
      {
        name: 'Track My Order',
        url: '/wheres-my-gift/',
      },
      {
        name: 'Help Centre',
        url: 'https://gourmet-basket.gorgias.help/en-US',
      },
      {
        name: 'Contact Us',
        url: '/contact/',
      },
      {
        name: 'Returns Policy',
        url: 'terms-and-conditions',
      },
      {
        name: 'GB Rewards Program',
        url: '/gb-rewards/',
      },
    ],
  },
  {
    heading: 'CORPORATE GIFTING',
    items: [
      {
        name: 'Corporate Orders',
        url: '/lp-corporate-gifting-website/',
      },
      {
        name: 'Request a Quote',
        url: '/lp/corporate-gifting-website/#form-holder',
      },
    ],
  },
];

export default function Footer() {
  return (
    <>
      <Rewards />
      <footer
        className="bg-brand-secondary px-3 pb-5"
        aria-labelledby="footer-heading"
      >
        <p className="text-brand-primary-light text-3xl font-playfairdisplay text-center italic leading-[150%] py-5 mx-5">
          Purveyors of fine food & happiness
        </p>
        <div className="mx-[60px] lg:mx-10">
          <div className="hidden md:grid grid-cols-2 gap-y-12 lg:grid-cols-4 container ">
            {navigation.map((navGroup, i) => (
              <div key={i}>
                <p className="py-4 text-left text-sm font-bold leading-4 tracking-widest text-white uppercase">
                  {navGroup.heading}
                </p>
                <ul>
                  {navGroup.items.map((item, i) => (
                    <li key={i} className=" text-white text-xs leading-6">
                      <a href={item.url}>{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div>
              <p className="py-4 text-left text-sm font-bold leading-4 tracking-widest text-white uppercase">
                STAY CONNECTED
              </p>
              <div className="flex justify-start gap-8">
                <Socials />
              </div>
              <p className="py-4 text-left text-sm font-bold tracking-widest text-white uppercase mt-5">
                SPECIAL OFFERS
              </p>
                <p className="py-4 text-left text-xs tracking-widest text-white">
                  Make your inbox more delicious with special offers and other goodies
                </p>
              <div className="klaviyo-form-Xz6Yh6"></div>
            </div>
          </div>
        </div>

        <div className="grid  place-items-center md:hidden">
          {navigation.map((navGroup, i) => (
            <Disclosure key={i} as="div">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={cn(
                      'uppercase flex justify-between  px-4 py-4 text-left text-sm font-bold leading-4 tracking-widest text-white focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75',
                      open ? 'text-brand-primary' : '',
                    )}
                  >
                    <span className="mr-1">{navGroup.heading}</span>
                    {open ? <FaCaretDown /> : <FaCaretUp />}
                  </Disclosure.Button>
                  <Disclosure.Panel className="te">
                    <div className="px-5 pt-1 pb-12">
                      <ul>
                        {navGroup.items.map((item, i) => (
                          <li
                            key={i}
                            className="text-center text-white text-xs leading-6"
                          >
                            <a href={item.url}>{item.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}

          <Disclosure as="div">
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={cn(
                    'uppercase flex justify-between  px-4 py-4 text-left text-sm font-bold leading-4 tracking-widest text-white focus:outline-none focus-visible:ring focus-visible:ring-brand-primary focus-visible:ring-opacity-75',
                    open ? 'text-brand-primary' : '',
                  )}
                >
                  <span className="mr-1">STAY CONNECTED</span>
                  {open ? <FaCaretDown /> : <FaCaretUp />}
                </Disclosure.Button>
                <Disclosure.Panel className="te">
                  <div className="px-5 pt-1 pb-12">
                    <div className="flex justify-center gap-8">
                      <Socials />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <PaymentIcons />
        <div className="text-[#FAFAF6] container text-center text-xs space-x-1">
          <Link href="/occasion/valentines-day-gift-hampers/">{`Valentine's Day Hampers`}</Link>
          <span>|</span>
          <Link href="/occasion/easter-gift-hampers/">{`Easter Hampers`}</Link>
          <span>|</span>
          <Link href="/occasion/mothers-day/">{`Mother's Day Gift Hampers`}</Link>
          <span>|</span>
          <Link href="/occasion/fathers-day/">{`Father's Day Gift Hampers`}</Link>
          <span>|</span>
          <Link href="/product-type/corporate-gift-hampers/">{`Corporate Gift Hampers`}</Link>
          <span>|</span>
          <Link href="/occasion/christmas-gift-hampers/">{`Christmas Gift Hampers`}</Link>
        </div>

        <div className="text-[#FAFAF6] container text-center text-xs space-x-1">
          <Link href="/sydney/">Gift Hampers Sydney</Link>
          <span>|</span>
          <Link href="/melbourne/">Gift Hampers Melbourne</Link>
          <span>|</span>
          <Link href="/brisbane/">Gifts Hampers Brisbane</Link>
          <span>|</span>
          <Link href="/perth/">Gift Hampers Perth</Link>
          <span>|</span>
          <Link href="/adelaide/">Gift Hampers Adelaide</Link>
        </div>

        <div className="text-[#FAFAF6] container text-center text-xs my-8">
          <p>
            To donate 10% of every gift hamper to a Charity of your choice,
            visit our sister company{' '}
            <a href="https://www.charityhampers.com.au/">
              <strong>CHARITY HAMPERS</strong>
            </a>
            <br />
            Address: Unit 3, 22 Narabang Way, Belrose, NSW 2085. Phone:{' '}
            <strong>1300 120 451</strong>
          </p>
          <p className="my-3">
            For delicious gourmet cupcakes visit our sister company{' '}
            <a href="https://thecupcakefactory.com.au/">
              <strong>The Cupcake Factory</strong>
            </a>
          </p>
          <p className="my-3">
            <strong>Available at MYER online and in stores nationwide</strong>
          </p>
          <p>
            COPYRIGHT {new Date().getFullYear()} GOURMET BASKET. STORE BY{' '}
            <a
              className="text-[#fafaf6] uppercase"
              href="https://blazecommerce.io/gb"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blaze Commerce.
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
